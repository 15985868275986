.nav {
    @apply sticky z-40 top-0 w-full bg-base-300 box-content stroke-[3px]
}

.nav_wrapper {
    width: 1144px;
    @apply flex flex-row items-center h-full px-4 mx-auto justify-between pt-[4px]
}


@media screen and (max-width: 1144px) {
    .nav_wrapper {
        @apply w-full 
    }
}


