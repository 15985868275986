.layout_wrapper {
    width: 1144px;
    @apply flex flex-col  mx-auto py-[20px] sm:py-[32px] min-h-screen px-4
}

@media screen and (max-width: 1144px) {
    .layout_wrapper {
        @apply w-full 
    }
}


