.underlined_button {
    @apply btn btn-ghost pt-2 flex normal-case;
    font-size: initial !important;
    padding: 2px 8px;
    border: 0px;
}

.underlined_button:after {
    display: inline-block;
    background: #00ff00;
    border-radius: 20px;
    height: 5px;
    width: 100%;
    content: "";
}

.primary:after {
    @apply bg-primary
}

.secondary:after {
    @apply bg-secondary
}

.underlined_button:disabled {
    @apply opacity-70
}